<template>
  <div class="contents-area faq-contents">
    <div class="full-content">
      <div class="container">
        <div class="h1-breadcrumbs">
          <h1 v-if="!$route.meta.article">
            {{ translations("Materials") }}
          </h1>
          <router-link
            class="btn btn-round"
            :to="'/materials/' + ($route.meta.article === 1 ? 'publications' : 'news')"
            v-if="$route.meta.article"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.21802 7.83336H13.3327V9.1667H5.21802L8.79402 12.7427L7.85135 13.6854L2.66602 8.50003L7.85135 3.3147L8.79402 4.25736L5.21802 7.83336Z"
                fill="#2D2E3C"
              />
            </svg>

            {{ translations("Back") }}
          </router-link>
          <Breadcrumbs />
        </div>
        <div class="articles-area">
          <ul v-if="!$route.meta.article" class="tabs-component-tabs">
            <li class="tabs-component-tab">
              <router-link
                to="/materials/news"
                exact-path
                class="tabs-component-tab-a"
                >{{ translations("News") }}</router-link
              >
            </li>
            <li class="tabs-component-tab">
              <router-link
                exact-path
                to="/materials/publications"
                class="tabs-component-tab-a"
                >{{ translations("Publications") }}</router-link
              >
            </li>
          </ul>
          <transition name="fade" mode="out-in" appear>
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {},
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters([
      "listSettings",
      "listLang",
      "listCurArticle",
      "translations",
    ]),
  },
  methods: {},
  mounted() {},
};
</script>
